@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  @apply px-4 w-full max-w-screen-lg mb-16;
}

.main {
  @apply font-sans px-2 py-8 h-full;
}

.nav {
  @apply uppercase font-bold tracking-widest flex justify-center mt-6 text-gray-400 text-xs;
}

.button {
  @apply px-6 py-4 rounded-lg text-lg text-white bg-black hover:bg-gray-700;
}
